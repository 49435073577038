<template>
  <v-overlay :value="show" class="justify-end">
    <v-slide-x-reverse-transition>
      <v-card tile v-if="currentUser" :light="!darkMode" :dark="darkMode" style="height: 100vh; width: 40vw">
        <v-card-title class="pl-4">
          <v-row no-gutters>
            <h3>{{ isEditing ? $t('DialogsTitles.EditUser') : $t('DialogsTitles.AddNewUser') }}</h3>
            <v-btn class="ml-auto" icon @click="close">
              <v-icon dark>mdi-close-thick</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-6">
          <v-form v-model="isValid" ref="editUserRef">
            <v-row v-if="isAdmin" class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">Company:</v-col>
              <v-col
                ><v-select
                  dense
                  :rules="[rules.required]"
                  hide-details="auto"
                  :items="CompanyList"
                  outlined
                  item-text="name"
                  item-value="id"
                  v-model="currentUser.companyId"
              /></v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('UserListHeaders.Name') }}:</v-col>
              <v-col> <v-text-field hide-details="auto" outlined v-model="currentUser.name" :rules="[rules.required]" dense></v-text-field></v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('UserListHeaders.PhoneNumber') }}:</v-col>
              <v-col>
                <v-text-field outlined hide-details="auto" v-model="currentUser.phoneNumber" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('UserListHeaders.Email') }}:</v-col>
              <v-col>
                <v-text-field hide-details="auto" v-model="currentUser.email" outlined :rules="[rules.required]" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">Roles:</v-col>
              <v-col cols="6" md="3">
                <v-checkbox color="activeButton" class="mt-0 pt-0" v-model="currentUser.roles" value="manager" label="Manager" dense hide-details />
              </v-col>
              <v-col v-if="isAdmin" cols="6" md="3" align-self="center">
                <v-checkbox color="activeButton" class="mt-0 pt-0" v-model="currentUser.roles" value="admin" label="Admin" dense hide-details />
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">Activation Status:</v-col>
              <v-col>
                <v-switch class="mt-0 pt-0" color="activeButton" v-model="userEnabled" :label="userEnabled ? 'Enabled' : 'Disabled'" hide-details />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="pl-4" no-gutters>
            <v-btn :block="$vuetify.breakpoint.mobile" @click="show = false" elevation="2">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
            <v-btn
              :block="$vuetify.breakpoint.mobile"
              @click="saveCurrentUser"
              color="success"
              class="ml-4"
              elevation="2"
              :loading="isSaving"
              :disabled="!isValid"
            >
              <v-icon left>mdi-check</v-icon>
              {{ isEditing ? $t('DialogsTitles.EditUser') : $t('DialogsTitles.AddNewUser') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-slide-x-reverse-transition>
  </v-overlay>
</template>

<script>
import apiService from '@/services/apiService.js';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateEditUser',
  data: () => ({
    show: false,
    isSaving: false,
    currentUser: {},
    isValid: null,
    CompanyList: [],
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'darkMode']),
    isEditing() {
      return this.currentUser?.id ? true : false;
    },
    userEnabled: {
      get() {
        return !this.currentUser.disabled;
      },
      set(val) {
        this.currentUser.disabled = !val;
      },
    },
  },
  methods: {
    async saveCurrentUser() {
      this.currentUser.email = this.currentUser.email ? this.currentUser.email.trim() : this.currentUser.email;
      this.currentUser.name = this.currentUser.name ? this.currentUser.name.trim() : this.currentUser.name;
      this.currentUser.phoneNumber = this.currentUser.phoneNumber ? this.currentUser.phoneNumber.trim() : this.currentUser.phoneNumber;
      this.isSaving = true;
      await apiService
        .createOrEditUser(this.currentUser)
        .then(() => {
          this.show = false;
          this.$emit('user-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    createUser(_companyId) {
      this.currentUser = {
        name: null,
        email: null,
        phoneNumber: null,
        companyId: _companyId,
        disabled: false,
        roles: [],
      };
      this.show = true;
    },
    editUser(user) {
      this.currentUser = _.cloneDeep(user);
      this.show = true;
    },
    async getCompanies() {
      this.CompanyList = await apiService.getCompanies();
    },
    close() {
      this.$emit('user-updated');
      this.show = false;
    },
  },
  created() {
    this.isAdmin ? this.getCompanies() : null;
  },
};
</script>

<style>
</style>