<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <r-card v-if="impactLevel">
      <template #header>
        <v-row no-gutters>
          <v-card-title>{{ $t('DialogsTitles.EditImpact') + ' ' + $util.impactLeveIdToText(impactLevel.impactLevelID) }}</v-card-title>
          <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
            <v-icon dark>mdi-close-thick</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card-text>
        <v-form class="mt-4" v-model="isValid" ref="editImpactLevelRef">
          <v-row>
            <v-col cols="12" md="4" v-if="isManager">
              <v-text-field
                v-model.number="impactLevel.timeMedian"
                :rules="[rules.required, rules.numberRule]"
                dense
                :label="$t('ImpactLevelItems.TimePercentage')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="isManager">
              <v-text-field
                v-model.number="impactLevel.resourcesMedian"
                :rules="[rules.required, rules.numberRule]"
                dense
                :label="$t('ImpactLevelItems.ResourcesPercentage')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="isManager">
              <v-text-field
                v-model.number="impactLevel.impactPercentage"
                :rules="[rules.required, rules.numberRule]"
                dense
                :label="$t('ImpactLevelItems.ImpactPercentage')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="SaveNewImpact" color="success" class="ml-0 ml-md-4 my-4" :disabled="!isValid">
          <v-icon left>mdi-check</v-icon>
          {{ $t('ButtonsLabel.Save') }}
        </v-btn>
      </v-card-actions>
    </r-card>
  </v-dialog>
</template>

<script>
import apiService from '@/services/apiService.js';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  data: () => ({
    impactLevel: null,
    projectId: null,
    companyId: null,
    show: false,
    isCompanyImpact: false,
    isValid: false,
    rules: {
      required: (value) => !!value || 'Input Needs at least 1 number',
      numberRule: (v) => {
        if (!v.toString().trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
        return 'Number has to be between 0 and 100';
      },
    },
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'isManager']),
  },
  methods: {
    async SaveNewImpact() {
      const data = {
        impactLevels: this.impactLevel,
      };
      await apiService.putImpactLevel(data);
      this.$emit('impact-updated');
      this.show = false;
    },
    EditImpact(ImpactLevel) {
      this.impactLevel = _.cloneDeep(ImpactLevel);
      this.show = true;
    },
  },
};
</script>

<style>
</style>